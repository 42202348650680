import { useQuery } from '@apollo/client';
import { debounce } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { AppEditorDocument } from '../gql';
import { serializeValues, type FormValues } from './';

const toContentOptions = (values: FormValues) => serializeValues(values).contentOptions;

export const useAppPreview = (values: FormValues, templateVersionId: number | null | undefined) => {
  const [contentOptions, setContentOptions] = useState(toContentOptions(values));

  const updateContentOptions = useMemo(
    () => debounce((vals: FormValues) => setContentOptions(toContentOptions(vals)), 500),
    [],
  );

  useEffect(() => updateContentOptions(values), [updateContentOptions, values]);

  const { data } = useQuery(AppEditorDocument, {
    variables: { contentOptions, templateVersionId: templateVersionId ?? 0 },
    skip: templateVersionId == null,
  });

  return useMemo(() => {
    if (data?.templateVersionPreview == null) return { payload: null };

    return {
      aspectRatio: data.templateVersionPreview.aspectRatio,
      payload: data.templateVersionPreview.contentItem,
    };
  }, [data?.templateVersionPreview]);
};
