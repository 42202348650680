import { Grid as MuiGrid } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { memo, type Dispatch, type SetStateAction } from 'react';
import { type DeviceList__Device as Device } from '../../queries/list.generated';
import { GridPagination, GridRow } from './';

export interface GridProps {
  devices: readonly Device[];
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  onSendCommand: (device: Device[]) => void;
  selectedIds: GridRowSelectionModel;
  totalCount: number;
}

export const Grid = memo(({ devices, onCheck, selectedIds, totalCount }: GridProps) => (
  <>
    <MuiGrid container spacing={2} sx={{ marginTop: 2 }}>
      {devices.map((device) => (
        <GridRow key={device.id} item={device} onCheck={onCheck} selectedIds={selectedIds} />
      ))}
    </MuiGrid>
    <GridPagination totalCount={totalCount} />
  </>
));

Grid.displayName = 'Grid';
