import { Box, Grid } from '@mui/material';
import { useEffect, useRef } from 'react';
import { RouterTabs, type RouterTabsProps } from '~/components/RouterTabs';

export interface FullscreenPanelContainerProps {
  children: React.ReactNode;
}

export const FullscreenPanelContainer = ({ children }: FullscreenPanelContainerProps) => {
  const containerRef = useRef<HTMLDivElement>();

  // fix column-reverse scrolling to bottom by default
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    container.scrollTop = -(container.scrollHeight - container.clientHeight);
  });

  return (
    <Grid
      container
      direction={{ xs: 'column-reverse', sm: 'row' }}
      ref={(x) => (containerRef.current = x ? x : undefined)}
      spacing={2}
      sx={{
        height: { xs: 'auto', sm: '100%' },
        flexWrap: { xs: 'nowrap', sm: 'wrap' },
        overflow: { xs: 'auto', sm: 'hidden' },
      }}
    >
      {children}
    </Grid>
  );
};

export interface FullscreenPanelLeftProps {
  children: React.ReactNode;
  tabs: RouterTabsProps['tabs'];
}

export const FullscreenPanelLeft = ({ children, tabs }: FullscreenPanelLeftProps) => (
  <Grid item xs={12} sm={4} zeroMinWidth>
    <nav>
      <RouterTabs
        sx={{ padding: 0, paddingTop: 1 }}
        tabs={tabs}
        variant="fullWidth"
        TabProps={{ replace: true }}
      />
    </nav>
    <Box
      sx={{
        background: (theme) => theme.palette.common.white,
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'column',
        height: { xs: 'auto', sm: 'calc(100vh - 112px)' },
        overflow: 'auto',
      }}
    >
      <Box sx={{ padding: 2 }}>{children}</Box>
    </Box>
  </Grid>
);

export interface FullscreenPanelRightProps {
  children: React.ReactNode;
}

export const FullscreenPanelRight = ({ children }: FullscreenPanelRightProps) => (
  <Grid item xs={12} sm={8}>
    <Box sx={{ padding: 2 }}>{children}</Box>
  </Grid>
);
