import { Box, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { ImpersonationBanner } from '~/components/ImpersonationBanner';
import { UploadDialog } from '~/components/UploadDialog';
import { UploadSnackbar } from '~/components/UploadSnackbar';
import { LoadingPane } from '~/components/loading-pane';
import { AppContextProvider, useFetchAppContext } from '~/contexts/app';
import { UploadProvider } from '~/contexts/upload';
import logo from '~/images/fc-logo-alt.svg';
import { Sidebar, Topbar } from './components';

export const MainLayout = () => {
  const { currentNetwork, currentUser, impersonating, trueUser } = useFetchAppContext();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  const hideNav = useMemo(() => {
    const { pathname } = location;
    return (
      /^\/networks\/\d+\/studio\/\d+$/.test(pathname) ||
      /^\/networks\/\d+\/content\/\d+\/(apps|media)\/\d+\/(details|settings|thumbnails|versions).*$/.test(
        pathname,
      ) ||
      /^\/networks\/\d+\/templates\/\d+\/create$/.test(pathname)
    );
  }, [location]);

  const theme = useTheme();

  useEffect(() => {
    if (!currentUser || !trueUser) return;

    Sentry.setUser({ email: trueUser.email });
    Sentry.setContext(
      'impersonated_user',
      currentUser.id === trueUser.id ? null : { email: currentUser.email },
    );
  }, [currentUser, trueUser]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  if (!currentUser || !currentNetwork || !trueUser) {
    return <LoadingPane in />;
  }

  return (
    <AppContextProvider
      currentUser={currentUser}
      currentNetwork={currentNetwork}
      impersonating={impersonating ?? false}
      trueUser={trueUser}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <UploadProvider>
          <ImpersonationBanner />
          <Box
            sx={{
              display: 'flex',
              height: impersonating ? 'calc(100% - 50px)' : '100%',
              marginTop: impersonating ? '50px' : '0px',
              [theme.breakpoints.down('sm')]: {
                marginTop: () => {
                  if (impersonating && hideNav) return '50px';
                  if (impersonating) return '100px';
                  if (hideNav) return '0px';
                  return '50px';
                },
              },
            }}
          >
            {!hideNav && (
              <>
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <Topbar logo={logo} toggleSidebar={handleDrawerToggle} />
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Sidebar logo={logo} open={mobileOpen} toggleSidebar={handleDrawerToggle} />
                </Box>
              </>
            )}

            <Box
              component="main"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflow: 'hidden',
              }}
            >
              <Outlet />
            </Box>
          </Box>

          <UploadDialog />
          <UploadSnackbar />
        </UploadProvider>
      </LocalizationProvider>
    </AppContextProvider>
  );
};
