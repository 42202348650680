import { matchIsValidColor } from 'mui-color-input';
import { array, boolean, mixed, number, object, string } from 'yup';
import { TemplateParamKind as Kind, type ContentOptionInput } from '~/generated/graphql';
import type {
  AppEditor__TemplateParam as TemplateParam,
  AppEditor__ContentItem as UserApp,
} from '../gql';

export type AppPayload = {
  contentOptions: ContentOptionInput[];
  thumbnailUri?: string;
};

export interface EditorUserApp extends UserApp {
  description?: string;
  id?: number;
  createdAt?: string;
  tags?: string[];
  updatedAt?: string;
  overlay?: {
    id: number;
  };
}

const templateParams = (app: UserApp | null | undefined) =>
  app?.templateVersion.groupedParams.flatMap(({ params }) => params) ?? [];

export type FormValues = {
  contentOptions: Record<string, unknown>;
  description?: string;
  thumbnailUri?: string;
};

export const initialValues = (app: EditorUserApp | null | undefined): FormValues => ({
  contentOptions: Object.fromEntries(
    app?.contentOptions.map(({ configValue, templateParam }) => [
      templateParam.id,
      configValue ?? (templateParam.array && templateParam.defaultValue == null ? [] : null),
    ]) ?? [],
  ),
  description: app?.description || '',
});

export const serializeValues = ({
  contentOptions,
  description,
  thumbnailUri,
}: FormValues): AppPayload => ({
  contentOptions: Object.entries(contentOptions).map(([templateParamid, configValue]) => ({
    templateParamId: parseInt(templateParamid),
    configValue,
  })),
  ...(description && { description }),
  ...(thumbnailUri && { thumbnailUri }),
});

export const optionSchema = (templateParam: TemplateParam) => {
  const { kind, label, nullable, options } = templateParam;
  const baseSchema =
    options != null
      ? mixed().oneOf(options.map((option) => option.value as never))
      : kind === Kind.Boolean
      ? boolean()
      : kind === Kind.Float
      ? number()
      : kind === Kind.Data || kind === Kind.Image || kind === Kind.Media || kind === Kind.Video
      ? number().integer().positive() // Legal IDs
      : kind === Kind.Integer
      ? number().integer()
      : kind === Kind.String || kind === Kind.Text
      ? string().trim()
      : kind === Kind.Color
      ? string().test((value) => value == null || matchIsValidColor(value))
      : mixed(); // Fall back to `unknown`, basically

  const trimmedSchema = baseSchema.transform((value: unknown) =>
    typeof value === 'string'
      ? value.trim()
      : typeof value === 'number'
      ? Number.isFinite(value)
        ? value
        : null
      : value == null
      ? null
      : value,
  );

  const arraySchema = templateParam.array
    ? array(trimmedSchema.required(`All ${label} values are required`)).min(nullable ? 0 : 1)
    : trimmedSchema;

  const schema = nullable ? arraySchema.nullable() : arraySchema.required();

  return schema.label(label);
};

export const buildValidationSchema = (app: UserApp | null | undefined) =>
  object({
    contentOptions: templateParams(app).reduce(
      (schema, templateParam) => schema.shape({ [templateParam.id]: optionSchema(templateParam) }),
      object({}),
    ),
    thumbnailUri: string(),
  });
