import { MoreVert } from '@mui/icons-material';
import { Divider, IconButton, Menu as MuiMenu, Tooltip } from '@mui/material';
import { useState, type ElementType } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteContent } from '~/api/content';
import { useDeleteContentFolder } from '~/api/content-folders';
import { useCopyContentItem, useUpgradeContentItem } from '~/api/content-items';
import { useCreateOverlay } from '~/api/overlays';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreDeleteAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { MoveContentDialog } from '../../components/move-content-dialog';
import { MoveFolderDialog } from '../../components/move-folder-dialog';
import {
  ContentMenuContentFolder,
  ContentMenuContentItem,
  ContentMenuMediaItem,
  RenameFolderDialog,
} from './';
import type {
  ContentMenuProps__ContentFolder,
  ContentMenuProps__ContentItem,
  ContentMenuProps__MediaItem,
} from './ContentMenu.generated';

export interface ContentMenuProps {
  buttonType?: 'action' | 'icon';
  item:
    | ContentMenuProps__ContentFolder
    | ContentMenuProps__ContentItem
    | ContentMenuProps__MediaItem;
  Icon?: ElementType;
}

export const ContentMenu = ({ buttonType = 'icon', item, Icon = MoreVert }: ContentMenuProps) => {
  const navigate = useNavigate();
  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<typeof item>();
  const mediaActionProps = moreTableActionProps(item);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [confirmUpgrade, confirmUpgradeProps] = useConfirmDialog();
  const [deleteContent] = useDeleteContent();
  const [deleteFolder] = useDeleteContentFolder();
  const [copyApp] = useCopyContentItem();
  const [createOverlay] = useCreateOverlay();
  const [upgradeApp] = useUpgradeContentItem();
  const [moveContentDialogOpen, setMoveContentDialogOpen] = useState(false);
  const [moveFolderDialogOpen, setMoveFolderDialogOpen] = useState(false);
  const [renameFolderDialogOpen, setRenameFolderDialogOpen] = useState(false);

  return (
    <>
      {buttonType === 'icon' ? (
        <Tooltip title="Actions" arrow>
          <IconButton
            color="secondary"
            aria-label="actions"
            onClick={mediaActionProps.onClick}
            size="small"
          >
            <Icon />
          </IconButton>
        </Tooltip>
      ) : (
        <ActionButton {...mediaActionProps} />
      )}
      <MuiMenu {...moreMenuProps}>
        {item.__typename === 'ContentFolder' && (
          <ContentMenuContentFolder
            item={item}
            onMove={() => {
              assert(moreMenuProps.context != null, 'media: no context');
              setMoveFolderDialogOpen(true);
              moreMenuProps.onClose();
            }}
            onRename={() => {
              setRenameFolderDialogOpen(true);
              moreMenuProps.onClose();
            }}
          />
        )}
        {item.__typename === 'ContentItem' && (
          <ContentMenuContentItem
            item={item}
            onCopy={async () => {
              assert(moreMenuProps.context != null, 'media: no context');
              const { data } = await copyApp({ variables: { contentItemId: item.id } });
              const id = data?.copyContentItem?.contentItem?.id;
              if (id) navigate(`apps/${id}/settings`);
              moreMenuProps.onClose();
            }}
            onOverlay={async () => {
              assert(moreMenuProps.context != null, 'media: no context');
              await createOverlay({ variables: { item: { contentItemId: item.id } } });
              moreMenuProps.onClose();
            }}
            onMove={() => {
              assert(moreMenuProps.context != null, 'media: no context');
              setMoveContentDialogOpen(true);
              moreMenuProps.onClose();
            }}
            onUpgrade={async () => {
              assert(moreMenuProps.context != null, 'media: no context');
              if (!(await confirmUpgrade())) {
                moreMenuProps.onClose();
                return;
              }
              await upgradeApp({ variables: { contentItemId: item.id } });
              moreMenuProps.onClose();
            }}
          />
        )}
        {item.__typename === 'MediaItem' && (
          <ContentMenuMediaItem
            item={item}
            onMove={() => {
              assert(moreMenuProps.context != null, 'media: no context');
              setMoveContentDialogOpen(true);
              moreMenuProps.onClose();
            }}
            onOverlay={async () => {
              assert(moreMenuProps.context != null, 'media: no context');
              await createOverlay({ variables: { item: { mediaItemId: item.id } } });
              moreMenuProps.onClose();
            }}
          />
        )}
        <Divider />
        <MoreDeleteAction
          {...moreActionProps}
          disabled={!item.canDestroy.value}
          onClick={async () => {
            assert(moreMenuProps.context !== undefined);
            if (!(await confirmDelete())) return;
            if (item.__typename === 'ContentFolder') {
              await deleteFolder({
                variables: { contentFolderId: moreMenuProps.context.id },
              });
            } else {
              await deleteContent({
                variables: {
                  items: [
                    {
                      contentableId: moreMenuProps.context.id,
                      contentableType:
                        moreMenuProps.context.__typename === 'ContentItem' ? 'APP' : 'MEDIA',
                    },
                  ],
                },
              });
            }
            moreMenuProps.onClose();
          }}
        />
      </MuiMenu>
      <MoveContentDialog
        contentIds={[`${item.__typename}-${item.id}`]}
        onClose={() => setMoveContentDialogOpen(false)}
        open={moveContentDialogOpen}
      />
      <MoveFolderDialog
        contentFolderId={item.id}
        onClose={() => setMoveFolderDialogOpen(false)}
        open={moveFolderDialogOpen}
      />
      {item.__typename === 'ContentFolder' && (
        <RenameFolderDialog
          contentFolder={item}
          onClose={() => setRenameFolderDialogOpen(false)}
          open={renameFolderDialogOpen}
        />
      )}
      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={
          item.__typename === 'ContentFolder' ? (
            'Deleting this Folder will delete all Media and Folders contained within this Folder.'
          ) : item.__typename === 'MediaItem' ? (
            <>
              This Media Item is used in{' '}
              <strong>
                {item.contentItemCount} App{item.contentItemCount === 1 ? '' : 's'}
              </strong>
              . We strongly suggest you replace it where it's being used before permanently deleting
              it.
            </>
          ) : (
            'Deleting this Content will remove it from Devices where it is being used.'
          )
        }
        title={`Delete ${item.__typename === 'ContentFolder' ? 'Folder' : 'Content'}`}
      />
      <ConfirmDialog
        {...confirmUpgradeProps}
        confirm="Upgrade"
        prompt="Upgrading this App will upgrade it to the latest version."
        title="Upgrade App"
      />
    </>
  );
};
